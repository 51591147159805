import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'

const residential = {
  context: 'Service With a Smile',
  title: 'Cam-Air Makes Your Comfort Our Top Priority',
  description:
    'Home comfort is a top priority for the residents of the Rio Grande Valley. We take care of all the technical details so you can stay cool on a hot Summer day, or keep warm during a cold front.',
  content: (
    <>
      <p>Services we provide for our residential customers:</p>
      <ul>
        <li>AC Repair</li>
        <li>AC Installation and Maintenance</li>
        <li>Furnace Installation and Maintenance</li>
        <li>Air Filtration Solutions</li>
        <li>and more!</li>
      </ul>
      <p>
        We have excellent HVAC repair and installation services, as well as AC maintenance services. South Texas HVAC
        systems will get used as they run to keep you cool. To keep them performing optimally they require maintenance.
        An inefficient AC unit can have a drastic effect on your comfort and wallet. Get started on a preventive
        maintenance plan with Cam-Air today!
      </p>
    </>
  ),
  subtitle: 'We are here to help',
  outro:
    'Whenever your AC unit gives up, we’re here to help you. We know this issues might happen at 3 in the morning or in the middle of the hottest day of the year. With Cam-Air, you can relax and let us get you back to normal!',
  cta: 'Request Service',
}

const Residential = () => (
  <Layout>
    <Seo title="Residential HVAC experts available in South Texas" />

    <div className="py-12 overflow-hidden lg:py-16 bg-gray-50">
      <div className="px-4 mx-auto space-y-8 max-w-7xl sm:px-6 lg:px-8">
        <div className="mx-auto text-base max-w-prose lg:max-w-none">
          <h2 className="text-base font-semibold tracking-wider uppercase text-primary">{residential.context}</h2>
          <h3 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 md:text-5xl">{residential.title}</h3>
        </div>
        <div className="relative z-10 mx-auto text-base max-w-prose lg:max-w-5xl lg:mx-0 lg:pr-72">
          <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">{residential.description}</p>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
          <div className="relative z-10">
            <div className="mx-auto prose text-gray-500 prose-indigo lg:max-w-none">
              {residential.content}
              <h3>{residential.subtitle}</h3>
              <p>{residential.outro}</p>
            </div>
            <div className="flex mx-auto mt-10 text-base max-w-prose lg:max-w-none">
              <div className="rounded-md shadow">
                <Link
                  to="/contact"
                  className="inline-flex justify-center px-4 py-2 text-xl font-medium text-white border border-transparent rounded-md shadow-sm lg:text-2xl bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
                >
                  {residential.cta}
                </Link>
              </div>
            </div>
          </div>
          <div className="mx-auto mt-12 text-base max-w-prose lg:mt-0 lg:max-w-none">
            <StaticImage
              className="object-cover w-full bg-white rounded-sm shadow-lg"
              src="../images/person-installing-ac.png"
              width={500}
              quality={95}
              formats={['auto', 'webp', 'avif']}
              alt="person installing ac unit"
              placeholder="none"
              imgStyle={{ objectPosition: 'top' }}
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Residential
